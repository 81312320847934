import axios from 'axios'
import Vue from 'vue'
import { v4 } from 'uuid'

/*  Setup global event bus. Exposed both through the ES6 module and the window global to allow
    event injection from external scripts and to share the same bus instance between different iParts
    Sometimes we need to get a unique ID for the DOM, particularly for matching labels to input elements
    so also creating a uid counter on the eventBus */
window.$mtapi = window.$mtapi || {}
window.$mtapi.eventBus = window.$mtapi.eventBus || new Vue({ data: { uid: 0 } })
const eventBus = window.$mtapi.eventBus

// Client context - baseUrl, isAnonymous, loggedInPartyId, selectedPartyId, websiteRoot, virtualDir
const clientContext = JSON.parse(document.getElementById('__ClientContext').value)

// Axios - set baseurl and add authorization header
axios.defaults.baseURL = window.gWebRoot || ''
axios.defaults.headers.common.RequestVerificationToken = document.getElementById('__RequestVerificationToken').value
axios.defaults.withCredentials = true

// Call callback after dom ready
const ready = (callback) => {
  if (document.readyState !== 'loading') {
    callback()
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback)
  } else {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState !== 'loading') { callback() }
    })
  }
}

// Get a unique id for html elements
const uid = () => {
  const result = 'uid-' + eventBus.uid
  eventBus.uid++
  return result
}

// Generate a GUID
const guid = () => {
  return v4()
}

// Check valid GUID
const isGuid = (value) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value)
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  eventBus, clientContext, axios, ready, uid, guid, isGuid, delay
}
