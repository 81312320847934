import mtapi from './core'

class CartManager {
  constructor () {
    this.current = null
  }

  async getCart (selectedPartyId = mtapi.clientContext.selectedPartyId, loggedInPartyId = mtapi.clientContext.loggedInPartyId) {
    try {
      this.current = null
      const response = await mtapi.axios.get(`/api/Cart?UserId=${selectedPartyId}&UpdatedBy=${loggedInPartyId}`)
      if (response.data.Count === 0) {
        const created = await this.createCart(selectedPartyId, loggedInPartyId)
        this.updateCartCount()
        return created
      } else {
        this.current = response.data.Items.$values[0]
        this.updateCartCount()
        return true
      }
    } catch (error) {
      this.current = null
      console.error('Error: could not get cart')
      return false
    }
  }

  async createCart (selectedPartyId = mtapi.clientContext.selectedPartyId, loggedInPartyId = mtapi.clientContext.loggedInPartyId) {
    try {
      this.current = null
      const currentDate = new Date()
      const cartData = {
        $type: 'Asi.Soa.Commerce.DataContracts.CartData, Asi.Contracts',
        UserId: selectedPartyId,
        ComboOrder: {
          $type: 'Asi.Soa.Commerce.DataContracts.ComboOrderData, Asi.Contracts',
          Order: {
            $type: 'Asi.Soa.Commerce.DataContracts.OrderData, Asi.Contracts',
            BillToCustomerParty: {
              $type: 'Asi.Soa.Commerce.DataContracts.CustomerPartyData, Asi.Contracts',
              PartyId: selectedPartyId
            },
            Delivery: {
              $type: 'Asi.Soa.Commerce.DataContracts.DeliveryDataCollection, Asi.Contracts',
              $values: [
                {
                  $type: 'Asi.Soa.Commerce.DataContracts.DeliveryData, Asi.Contracts'
                }
              ]
            },
            Lines: {
              $type: 'Asi.Soa.Commerce.DataContracts.OrderLineDataCollection, Asi.Contracts',
              $values: []
            },
            SoldToCustomerParty: {
              $type: 'Asi.Soa.Commerce.DataContracts.CustomerPartyData, Asi.Contracts',
              PartyId: selectedPartyId
            }
          }
        },
        UpdateInformation: {
          $type: 'Asi.Soa.Core.DataContracts.EntityUpdateInformationData, Asi.Contracts',
          CreatedBy: loggedInPartyId,
          CreatedOn: currentDate.toISOString().slice(0, -1),
          UpdatedBy: loggedInPartyId,
          UpdatedOn: currentDate.toISOString().slice(0, -1)
        }
      }
      const response = await mtapi.axios.post('/api/Cart', cartData)
      if (response.status === 201) {
        this.current = response.data
        return true
      } else {
        console.error('Error: could not create cart')
        return false
      }
    } catch (error) {
      this.current = null
      console.error('Error: could not create cart')
      return false
    }
  }

  async clearCart () {
    try {
      if (this.current) {
        const response = await mtapi.axios.delete(`/api/Cart/${this.current.CartId}`)
        this.current = null
        this.updateCartCount()
        return response.status === 200
      } else {
        this.current = null
        return true
      }
    } catch (error) {
      this.current = null
      console.error('Error: could not clear cart')
      return false
    }
  }

  async addProductToCart (productCode, quantity = 1) {
    try {
      if (this.current) {
        const lineIndex = this.productLineInCart(productCode)
        if (lineIndex > -1) {
          this.current.ComboOrder.Order.Lines.$values[lineIndex].QuantityOrdered.Amount += quantity
        } else {
          const lineData = {
            $type: 'Asi.Soa.Commerce.DataContracts.OrderLineData, Asi.Contracts',
            OrderLineId: mtapi.guid(),
            Item: {
              $type: 'Asi.Soa.Commerce.DataContracts.ItemSummaryData, Asi.Contracts',
              ItemId: productCode
            },
            LineNumber: 1,
            QuantityOrdered: {
              $type: 'System.Nullable`1[[Asi.Soa.Commerce.DataContracts.QuantityData, Asi.Contracts]], mscorlib',
              Amount: quantity
            },
            CanCombine: true
          }
          this.current.ComboOrder.Order.Lines.$values.push(lineData)
        }
        const response = await mtapi.axios.put(`/api/Cart/${this.current.CartId}`, this.current)
        this.current = response.data
        this.updateCartCount()
        return true
      } else {
        console.warn('Warning: cannot add product to cart, please load a cart first')
        return false
      }
    } catch (error) {
      this.current = null
      console.error('Error: could not add product to cart')
      return false
    }
  }

  productLineInCart (productCode) {
    if (this.current) {
      return this.current.ComboOrder.Order.Lines.$values.findIndex(x => x.Item.ItemId === productCode)
    } else {
      return -1
    }
  }

  productQuantityInCart (productCode) {
    if (this.current) {
      const lineIndex = this.productLineInCart(productCode)
      if (lineIndex > -1) {
        return this.current.ComboOrder.Order.Lines.$values[lineIndex].QuantityOrdered.Amount
      }
    }
    return 0
  }

  async removeProductFromCart (productCode, quantity = 1) {
    try {
      if (this.current) {
        const currentQuantity = this.productQuantityInCart(productCode)
        if (currentQuantity === 0) { return false }
        const lineIndex = this.productLineInCart(productCode)
        if (currentQuantity - quantity > 0) { this.current.ComboOrder.Order.Lines.$values[lineIndex].QuantityOrdered.Amount -= quantity } else { this.current.ComboOrder.Order.Lines.$values.splice(lineIndex, 1) }
        const response = await mtapi.axios.put(`/api/Cart/${this.current.CartId}`, this.current)
        this.current = response.data
        this.updateCartCount()
        return true
      } else {
        console.warn('Warning: cannot remove product from cart, please load a cart first')
        return false
      }
    } catch (error) {
      this.current = null
      console.error('Error: could not remove product from cart')
      return false
    }
  }

  updateCartCount () {
    if (typeof window.updateCartItemCount === 'function') { window.updateCartItemCount() }
  }
}

const cart = new CartManager()

export default {
  cart
}
